/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('echecks', {
    parent: 'root',
    url: '/echecks',
    templateUrl: 'apps/echecks/templates/acp-echecks.ng.html',
    data: {
      permissions: {
        only: 'echeckEnabled',
        redirectTo: {
          echeckEnabled: 'dashboard'
        }
      }
    },
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.apps.echecks" */ 'apps/echecks').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
